import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Flavor Express Hakkında
			</title>
			<meta name={"description"} content={"Flavor Express basit ama tutkulu bir fikirle başladı: çeşitli ve lezzetli yiyecekleri herkes için kolayca erişilebilir kılmak. Yolculuğumuz, farklı mutfaklara duyulan sevgiyi ve bunları başkalarıyla paylaşma sevincini bir araya getiren bir lezzet kutlamasıdır."} />
			<meta property={"og:title"} content={"Hakkımızda | Flavor Express Hakkında"} />
			<meta property={"og:description"} content={"Flavor Express basit ama tutkulu bir fikirle başladı: çeşitli ve lezzetli yiyecekleri herkes için kolayca erişilebilir kılmak. Yolculuğumuz, farklı mutfaklara duyulan sevgiyi ve bunları başkalarıyla paylaşma sevincini bir araya getiren bir lezzet kutlamasıdır."} />
			<meta property={"og:image"} content={"https://areosflind.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://areosflind.com/img/6425534.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://areosflind.com/img/6425534.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://areosflind.com/img/6425534.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://areosflind.com/img/6425534.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://areosflind.com/img/6425534.png"} />
			<meta name={"msapplication-TileImage"} content={"https://areosflind.com/img/6425534.png"} />
			<meta name={"msapplication-TileColor"} content={"https://areosflind.com/img/6425534.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Flavor Express Hakkında
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Flavor Express basit ama tutkulu bir fikirle başladı: çeşitli ve lezzetli yiyecekleri herkes için kolayca erişilebilir kılmak. Yolculuğumuz, farklı mutfaklara duyulan sevgiyi ve bunları başkalarıyla paylaşma sevincini bir araya getiren bir lezzet kutlamasıdır.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://areosflind.com/img/a1.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://areosflind.com/img/a2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://areosflind.com/img/a3.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Mutfak Felsefemiz
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Flavor Express'te yemeği sadece bir besin kaynağı olarak görmüyoruz - yemek bir kültür dili, bir konfor sembolü ve evinizden çıkmadan dünyanın lezzetlerini keşfetmenin bir aracıdır. Hizmetimiz, bir tıklama kolaylığıyla yeni tatlar keşfetmenin ve tanıdık tatları yeniden ziyaret etmenin keyfi üzerine kurulmuştur.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Flavor Express'in Temel Unsurları
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Çeşitli Restoran Seçkisi - Yerel mücevherlerden popüler zincirlere kadar çok çeşitli restoranlarla ortaklık kurarak her damak tadına uygun çeşitli seçenekler sunuyoruz.
Kalite ve Hıza Bağlılık - Yiyecekleri hızlı ve taze bir şekilde teslim etme konusundaki kararlılığımız, bizi yemek teslimatı ortamında farklı kılan şeydir.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-secondary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://areosflind.com/img/a4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});